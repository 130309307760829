//排课管理
<template>
  <div class="course_manager">
    <!-- 顶部的日课程表,周课程表,上课教室,排课时段 -->
    <div class="top margin_left_14 margin_right_14">
      <!-- 顶部的导航 -->
      <TopNavBar :tabArr="tabArr" @onItemClick="onItemClick"></TopNavBar>
      <!-- 上课教室,排课时段 -->
      <!-- <div class="search_bt_active com_bt_action margin_left_40" @click.stop="goArrangingTime">上课时间</div> -->
      <el-button type="primary" style="margin-left: 100px" size="medium" @click.stop="goSchooltime"> 上课教室 </el-button>
      <el-button type="primary" style="margin-left: 40px" size="medium" @click.stop="goArrangingTime"> 上课时间 </el-button>
    </div>
    <!-- 切换日课程表和周课表的 router_view -->
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabArr: ["日课程表", "周课表"],
      //路由跳转子路由
      path: "/Home/CourseManager/DayLessons",
    };
  },
  created() {
    // console.log("重新触发了排课管理", this.path);
    //默认加载日课程表
    this.$router.replace({ path: this.path });
  },

  methods: {
    //日课表,周课表的点击事件
    onItemClick(pos) {
      pos == 0 ? (this.path = "/Home/CourseManager/DayLessons") : (this.path = "/Home/CourseManager/WeekLessons");
      this.$router.push({ path: this.path });
    },

    //上课教室
    goSchooltime() {
      //跳转到上课教室界面
      this.$router.push({ path: "/Home/EditClassRoom" });
    },

    //排课时段
    goArrangingTime() {
      //跳转到上课教室界面
      this.$router.push({ path: "/Home/EditClassTime" });
    },
  },
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
.course_manager {
  height: calc(100vh - 78px);
  .top {
    display: flex;
    align-items: center;
  }
}
</style>
